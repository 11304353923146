import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import AdminBoutiqueForm from '../../core/sysext/CMS/containers/Admin/containers/Boutique/components/Form';
import AdminBoutiqueList from '../../core/sysext/CMS/containers/Admin/containers/Boutique/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const AdminUsers: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? (
        <AdminBoutiqueForm docId={star} {...props} />
      ) : (
        <AdminBoutiqueList {...props} />
      )}
    </Backoffice>
  );
};

export default AdminUsers;
