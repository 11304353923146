import { BoutiqueType, FirebaseFirestore } from '@innedit/innedit';
import React, { FC, useEffect, useState } from 'react';

import ListItem from '../../../../../Boutique/components/new/Item';
import { ListItemProps } from '../../../../../Boutique/components/new/props';

const Item: FC<ListItemProps<BoutiqueType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<BoutiqueType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc || !doc.exists) {
    return null;
  }

  const data = doc.data();

  if (!data) {
    return null;
  }

  const numero = doc.get('numero') || doc.id;

  return (
    <ListItem
      actions={{ tooltip: numero, type: 'tooltip' }}
      contentClassName="flex-auto flex flex-row"
      displayActionId={false}
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <strong className="name">{data.name}</strong>
    </ListItem>
  );
};

export default Item;
