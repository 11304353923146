import { BoutiqueData } from '@innedit/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CMSView from '../../../../../../components/View/index';
import List from '../../../../../Boutique/components/new/List';
import params from '../../params.json';
import Item from './Item';

const AdminBoutiqueList: FC = () => {
  const { t } = useTranslation();

  return (
    <CMSView>
      <List
        item={Item}
        itemPathnamePrefix="/admin/boutiques/"
        model={new BoutiqueData({ params })}
        title={t('boutiques')}
      />
    </CMSView>
  );
};

export default AdminBoutiqueList;
