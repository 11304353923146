import { BoutiqueData } from '@innedit/innedit';
import React, { FC, SyntheticEvent } from 'react';

import CMSView from '../../../../../../components/View/index';
import Form from '../../../../../Boutique/components/new/Form';
import params from '../../params.json';

const AdminBoutiqueForm: FC<{ docId: string }> = ({ docId }) => {
  const handlePreviousOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // TODO gérer cette fonction
  };

  const handleNextOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // TODO gérer cette fonction
  };

  return (
    <CMSView>
      <Form
        docId={docId}
        itemPathnamePrefix="/admin/boutiques/"
        model={new BoutiqueData({ params })}
        name="item"
        nextOnClick={handleNextOnClick}
        previousOnClick={handlePreviousOnClick}
      />
    </CMSView>
  );
};

export default AdminBoutiqueForm;
